import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

//actions
export const UPDATE_COUNTER_CHART_TYPES =
  "updateSPLWESDASHBOARDCounterChartsTypes";
export const UPDATE_DASHBOARD_FILTERS_TYPES =
  "updateSPLWESDASHBOARDFiltersTypes";

// mutations
export const SET_COUNTER_CHART_TYPES = "setSPLWESDASHBOARDCounterChartsTypes";
export const SET_DASHBOARD_FILTERS_TYPES = "setSPLWESDASHBOARDFiltersTypes";
export const SET_DASHBOARD_FILTERS = "setSPLWESDASHBOARDFilters";
export const SET_DASHBOARD_URL = "setSPLWESDASHBOARDUrl";
export const SET_FILTER_BUTTON_DISABLED = "setSPLWESDASHBOARDSetFilterDisabled";

const state = {
  charts: [],
  filter_types: [],
  filters: [],
  url: null,
  filter_disabled: false,
};

const getters = {
  getSPLWESDASHBOARDCharts: function (state) {
    return state.charts;
  },
  getSPLWESDASHBOARDFilters: function (state) {
    return state.filters;
  },
  getSPLWESDASHBOARDFilterDisabled: function (state) {
    return state.filter_disabled;
  },
  getSPLWESDASHBOARDFilterClients: function (state) {
    return state.filter_types.clients;
  },
};

const actions = {
  async [UPDATE_DASHBOARD_FILTERS_TYPES](context, payload) {
    try {
      const { data } = await ApiService.post(
        "/dashboards/dashboardWes/filters",
        payload
      );
      context.commit(SET_DASHBOARD_FILTERS_TYPES, data.filters);
    } finally {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
      context.commit(SET_PAGE_LOADING, false);
    }
  },

  async [UPDATE_COUNTER_CHART_TYPES](context) {
    try {
      const { data } = await ApiService.post(
        context.state.url,
        context.state.filters
      );
      context.commit(SET_COUNTER_CHART_TYPES, data.charts);
    } finally {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
      context.commit(SET_PAGE_LOADING, false);
    }
  },
};

const mutations = {
  [SET_COUNTER_CHART_TYPES](state, charts) {
    state.charts = charts;
  },
  [SET_DASHBOARD_FILTERS_TYPES](state, filters) {
    state.filter_types = filters;
  },
  [SET_DASHBOARD_FILTERS](state, filters) {
    state.filters = filters;
  },
  [SET_DASHBOARD_URL](state, url) {
    state.url = url;
  },
  [SET_FILTER_BUTTON_DISABLED](state, value) {
    state.filter_disabled = value;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
